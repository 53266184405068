import { Box } from '@mui/material';
import PageLayout from '../../Global/PageLayout';
import WaiverOptionsList from './WaiverOptionsList';
import { WaiverSectionProps } from '../../../types/waivers';
import { useScrollToOnDisabledClick } from '../../../hooks/global/useScrollToOnDisabledClick';

export default function Acknowledgments({
  waiver,
  onNext,
  onBack,
  waiverForm,
  alert,
}: WaiverSectionProps) {
  const { scrollToRef, handleDisabledClick } = useScrollToOnDisabledClick();

  const isValid = Object.values(waiverForm.value.acknowledgments).every(
    Boolean,
  );

  return (
    <PageLayout
      alert={alert}
      header="Acknowledgments"
      subheader={waiver?.acknowledgmentSettings?.intro}
      onNext={onNext}
      onBack={onBack}
      nextDisabled={!isValid}
      onPrimaryDisabledClick={handleDisabledClick}>
      <Box ref={scrollToRef}>
        <WaiverOptionsList
          options={waiver?.acknowledgmentSettings?.options}
          type="acknowledgments"
          waiverForm={waiverForm}
        />
      </Box>
    </PageLayout>
  );
}

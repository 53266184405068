import * as React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { Document, Page } from 'react-pdf';
import PageLayout from '../../Global/PageLayout';
import SignaturePad from './SignaturePad';
import { WaiverSectionProps } from '../../../types/waivers';
import { getDefaultStaticLiabilityText } from '../../../utils/waivers';
import { useScrollToOnDisabledClick } from '../../../hooks/global/useScrollToOnDisabledClick';

export default function StaticLiabilityWaiver({
  employee,
  business,
  waiver,
  onNext,
  onBack,
  waiverForm,
  alert,
}: WaiverSectionProps) {
  const sigCanvas = React.useRef<SignatureCanvas | null>(null);
  const { scrollToRef, handleDisabledClick } = useScrollToOnDisabledClick();

  const [width, setWidth] = React.useState(0);
  const [numPages, setNumPages] = React.useState(0);
  const [isTouched, setIsTouched] = React.useState(false);

  const documentContainerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const onResize = () => {
      if (documentContainerRef.current) {
        setWidth(documentContainerRef.current.offsetWidth);
      }
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const createSignature = () => {
    if (!sigCanvas.current) throw new Error('Signature canvas not found');
    const isEmpty = sigCanvas.current.isEmpty();
    if (isEmpty) throw new Error('Signature is required');
    const url = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    return url;
  };

  const handleNextClick = () => {
    try {
      const url = createSignature();
      waiverForm.set(prev => ({
        ...prev,
        staticLiabilitySignature: url,
      }));
      onNext();
    } catch {
      // EMPTY
    }
  };

  const onBegin = () => {
    setIsTouched(true);
  };

  const onClear = () => {
    setIsTouched(false);
  };

  const onDocumentLoadSuccess = ({ numPages: num }: { numPages: number }) => {
    setNumPages(num);
  };

  return (
    <PageLayout
      alert={alert}
      header="Liability Waiver"
      onNext={handleNextClick}
      onBack={onBack}
      nextDisabled={!isTouched}
      onPrimaryDisabledClick={handleDisabledClick}>
      {!!waiver?.staticWaiverSettings?.documentLink && (
        <Box
          ref={documentContainerRef}
          width="100%"
          height={300}
          sx={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          <Document
            loading={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={300}
                width="100%">
                <CircularProgress color="primary" />
              </Box>
            }
            file={waiver?.staticWaiverSettings?.documentLink}
            onLoadSuccess={onDocumentLoadSuccess}>
            <Box
              maxHeight={300}
              width={width}
              sx={{
                overflowX: 'hidden',
                overflowY: 'auto',
              }}>
              {numPages &&
                Array.from({ length: numPages }, (_, index) => index + 1).map(
                  pageNumber => (
                    <Page
                      key={pageNumber}
                      width={width}
                      loading={() => null}
                      pageNumber={pageNumber}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ),
                )}
            </Box>
          </Document>
        </Box>
      )}

      {!waiver?.staticWaiverSettings?.documentLink && (
        <Paper
          sx={theme => ({
            backgroundColor: theme.palette.lightGrey.main,
            padding: 2,
            border: `1px solid ${theme.palette.outlineGrey}`,
            color: theme.palette.darkGrey.main,
            maxHeight: '30vh',
            overflow: 'scroll',
            textAlign: 'justify',
            boxShadow: 'none',
          })}>
          <Typography
            variant="body2"
            whiteSpace="pre-line"
            dangerouslySetInnerHTML={{
              __html: getDefaultStaticLiabilityText({
                employee: {
                  firstName: employee?.firstName || '',
                  lastName: employee?.lastName || '',
                },
                business,
              }).trim(),
            }}
          />
        </Paper>
      )}
      <Typography variant="h5" whiteSpace="pre-line">
        {waiver?.staticWaiverSettings?.attestationText}
      </Typography>
      <Box ref={scrollToRef}>
        <SignaturePad
          sigCanvas={sigCanvas}
          onBegin={onBegin}
          onClear={onClear}
        />
      </Box>
    </PageLayout>
  );
}

import { useRef } from 'react';

export function useScrollToOnDisabledClick() {
  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const handleDisabledClick = () => {
    if (scrollToRef?.current) {
      const headerElement = document.getElementById('scroll-offset-element');
      const topOffset = headerElement ? headerElement.offsetHeight + 20 : 0;
      const elementPosition = scrollToRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - topOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return { scrollToRef, handleDisabledClick };
}
